import React from "react";

const NotFound = () => {
    return(
        <div>
            <h2>Not Found</h2>
        </div>
    );
}

export default NotFound;